/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Bio = ({authors}) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          description
          social {
            twitter
          }
        }
      }
    }
    `)

  return (
    <div>
      { !authors.length ?
          data.site.siteMetadata.description
          : authors.map(author => (
            <p className='mb-4 italic' key={author.name}>
              <a href={author.link} className="font-semibold hover:underline">{author.name}</a> {author.summary}
            </p>
          ))}
    </div>
        //<StaticImage
          //className="bio-avatar"
          //layout="fixed"
          //formats={["auto", "webp", "avif"]}
          //src="../images/profile-pic.png"
          //width={50}
          //height={50}
          //quality={95}
          //alt="Profile picture"
        ///>
  )
}

export default Bio
