import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import '../markdown.css'

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  const image = getImage(post.frontmatter.cover)


  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <article
        className="container max-w-4xl mx-auto my-8 mt-4 blog-post sm_mt-16"
        itemScope
        itemType="http://schema.org/Article"
      >

        <div className='flex flex-wrap justify-between my-4'>
          <Link to='/news' className='block hover_underline'>
            ← Show all
          </Link>
        </div>

        <header className="my-8">
          <h1 itemProp="headline" className="text-4xl">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
          <GatsbyImage image={image} alt={post.frontmatter.title} className="w-full h-full my-4 max-h-80" objectFit="contain" itemProp="image" />
          <Bio authors={post.frontmatter.authors} />
        </header>

        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
          className="my-4 markdown"
        />
        <hr />
        {/*<footer>
          <Bio />
        </footer>*/}
      </article>

      <nav className="container mx-auto my-8 blog-post-nav">
        <ul className="flex flex-wrap justify-between p-0 list-none">
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev" className="hover:underline">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next" className="hover:underline">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 1024
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        authors {
          name
          summary
          link
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
